/* @license 2020-2023 Jason Go | Apache-2.0 | https://github.com/jasongodev/osi4kjb-embed-checkout-form */
"use strict";
(() => {
  // src/lib/utils.ts
  var $$ = (s, base = document) => {
    return base.querySelectorAll(s);
  };
  var domReady = (callback) => document.readyState.length > 7 ? callback() : setTimeout(domReady, 9, callback);
  var LOCATION = new URL(window.location.href);
  var isOfferPage = /* @__PURE__ */ LOCATION.pathname.match(/^\/offers\/.{8}/) !== null;

  // src/lib/lazyloader.ts
  var onIntersection = (load, loaded) => {
    return (entries, observer) => {
      entries.forEach((entry) => {
        const target = entry.target;
        if (entry.intersectionRatio > 0 || entry.isIntersecting) {
          observer.unobserve(entry.target);
          if (target.dataset.loaded !== "true") {
            load(target);
            target.dataset.loaded = "true";
            loaded(target);
          }
        }
      });
    };
  };
  var lazy = (element, load, loaded = (el) => {
  }, rootMargin = "0px", threshold = 0) => {
    var _a;
    let observer;
    if ("IntersectionObserver" in window) {
      observer = new IntersectionObserver(onIntersection(load, loaded), {
        rootMargin,
        threshold
      });
    }
    element.style.background = (_a = element.dataset.background) != null ? _a : "";
    return {
      observe: () => {
        if (element.dataset.loaded !== "true") {
          if (typeof observer !== "undefined" && (element.dataset.loading === "lazy" || typeof element.dataset.loading === "undefined")) {
            observer.observe(element);
          } else {
            load(element);
            element.dataset.loaded = "true";
            loaded(element);
          }
        }
      }
    };
  };

  // src/lib/compatibility.ts
  var convertV1 = () => {
    $$("script").forEach((v1script) => {
      var _a;
      const checkoutUrl = ((_a = v1script.innerHTML.match(/ecf\s*\(\s*(['"`])(.+?)\1/im)) != null ? _a : ["", "", ""])[2];
      if (checkoutUrl !== "") {
        v1script.insertAdjacentHTML("afterend", `<div data-ecf="${checkoutUrl}" data-ecf-v1="1"></div>`);
        v1script.remove();
      }
    });
  };
  var showDeprecationWarningsInCheckout = () => {
    if (window.confirm("UPDATE: The Embed Checkout Form Script V2 is now available! OPEN THE HOW-TO PAGE?")) {
      window.open("https://github.com/jasongodev/osi4kjb-embed-checkout-form", "_blank");
    }
  };

  // src/index.ts
  var savedDataEcf = {};
  var isEditorMode = LOCATION.searchParams.get("editor") === "true";
  var attachCheckout = (target) => {
    var _a, _b;
    const offerPath = ((_a = target.dataset.ecf.match(/\/offers\/(.+)/)) != null ? _a : [""])[0];
    if (offerPath === "") {
      if (isEditorMode)
        target.innerHTML = `<b>Warning:</b> "${target.dataset.ecf}" is not a valid Kajabi checkout URL.`;
      return;
    }
    target.style.display = "none";
    const iframeURL = new URL(LOCATION.origin + offerPath);
    const couponCode = LOCATION.searchParams.get("coupon_code");
    if (couponCode !== null)
      iframeURL.searchParams.set("coupon_code", couponCode);
    const iframeId = target.id + "-iframe";
    const cssLoaderSelector = "." + target.id + ".cssloader";
    const cssLoader = (_b = target.dataset.cssLoader.replace(/.loader/gm, cssLoaderSelector)) != null ? _b : "";
    target.insertAdjacentHTML(
      "afterend",
      `<style class="${target.id}">${cssLoaderSelector}{position:relative;margin:auto;top:40px}${cssLoader}</style><div class="${target.id} cssloader"></div><iframe src="${iframeURL.href}" id="${iframeId}" style="visibility:hidden" class="${target.id}"></iframe>`
    );
    const iframe = $$("#" + iframeId)[0];
    for (const key in target.dataset) {
      iframe.dataset[key] = target.dataset[key];
    }
    if (target.dataset.iframeScript !== "") {
      iframe.addEventListener("load", () => {
        if (iframe.contentDocument !== null) {
          const libScript = iframe.contentDocument.createElement("script");
          libScript.src = target.dataset.iframeScript;
          iframe.contentDocument.head.appendChild(libScript);
        }
      });
    }
  };
  var processECFDivs = (ecfDivs, config) => {
    ecfDivs.forEach((div) => {
      var _a;
      for (const property in config) {
        div.dataset[property] = (_a = div.dataset[property]) != null ? _a : config[property];
      }
      div.dataset.processed = "true";
      div.dataset.loaded = "";
      div.id = div.id === "" ? `${Math.random()}`.replace("0.", "ecf-") : div.id;
      div.dataset.ecfId = div.id;
      savedDataEcf[div.id] = savedDataEcf[div.id] !== void 0 && div.dataset.mutation === "false" ? savedDataEcf[div.id] : div.dataset.ecf;
      div.dataset.ecf = savedDataEcf[div.id];
      if (isEditorMode)
        div.dataset.loading = "eager";
      div.innerHTML = "&nbsp;";
      div.style.display = "block";
      $$("." + div.id).forEach((el) => {
        el.remove();
      });
      lazy(
        div,
        (target) => {
          attachCheckout(target);
        },
        void 0,
        div.dataset.lazyRootMargin,
        parseInt(div.dataset.lazyThreshold)
      ).observe();
    });
  };
  var defaultConfig = {
    loading: "lazy",
    lazyThreshold: "0",
    lazyRootMargin: "0px",
    mutation: "true",
    iframeScript: "",
    cssLoader: "@keyframes loading{to{background-position:215px 0,0 0,15px 150px,15px 180px}}.loader{width:215px;height:220px;background:linear-gradient(.25turn,transparent,#fff,transparent),linear-gradient(#ddd,#ddd),linear-gradient(#ddd,#ddd),linear-gradient(#ddd,#ddd);background-color:#fff;background-repeat:no-repeat;background-size:215px 220px,215px 130px,100px 15px,150px 15px;background-position:-215px 0,0 0,15px 150px,15px 180px;animation:loading 1.5s infinite}"
  };
  var initializeECF = (config = defaultConfig) => {
    var _a;
    (_a = window.disconnectECF) == null ? void 0 : _a.call(window);
    processECFDivs($$("div[data-ecf]"), config);
    const ecfDivObserver = new MutationObserver((mutation) => {
      mutation.forEach((record) => {
        const ecfDivs = record.type === "attributes" ? [record.target] : [...record.addedNodes];
        ecfDivs.forEach((div) => {
          if (div.nodeType === 1 && div.nodeName === "DIV" && div.hasAttribute("data-ecf") && !div.hasAttribute("data-processed")) {
            if (record.type === "childList" && config.mutation === "false") {
              div.remove();
            } else {
              processECFDivs([div], config);
            }
          }
        });
      });
    });
    ecfDivObserver.observe(document.body, {
      attributeFilter: ["data-processed"],
      characterData: false,
      childList: true,
      subtree: true
    });
    window.disconnectECF = () => {
      ecfDivObserver.disconnect();
      delete window.disconnectECF;
    };
  };
  var runAsBrowserScript = (config = defaultConfig) => {
    domReady(() => {
      var _a, _b, _c;
      if (isOfferPage) {
        if (isEditorMode)
          showDeprecationWarningsInCheckout();
        return;
      }
      if (window.disconnectECF !== void 0)
        return;
      convertV1();
      console.log("here here");
      config.iframeScript = (_b = (_a = document.currentScript) == null ? void 0 : _a.src.replace("ecf.", "ecf-scripts.")) != null ? _b : "";
      initializeECF(Object.assign(config, (_c = document.currentScript) == null ? void 0 : _c.dataset));
    });
  };

  // build/ecf.ts
  runAsBrowserScript();
})();
